:root {
  --transition-property: width, height, margin, box-shadow, padding,
  transform, background-color, color, opacity, min-height, min-width,
  max-width, max-height;
  --transition-duration-to: 300ms;
  --scale-active: scale(0.94);
}

.App {
  min-height: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.hidden {
  width: 0 !important;
  display: none !important;
}