.login {
    /* display: block; */
    display: flex;
    /* position: absolute; */
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 10.4em);
    width: 100%;
}
.login .flexbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.6em;
    max-width: calc(100%, 20em);
    width: 20em;
}

.login-panel {
    transition-property: var(--transition-property);
    transition-duration: var(--transition-duration-to);
    border: 2px solid #f0f0f0;
    background-color: #f7f7f7;
    border-radius: 12px;
    padding: 1.6em;
    width: 100%;
}

.login-title-container {
    padding-bottom: 1.6em;
}
.login-title {
    text-align: center;
}

.field-label {
    /* display: inline-block; */
    display: block;
    padding-bottom:0.3em;
    /* width: 100%; */
    /* font-weight: 600; */
}
.field {
    font-family: 'Yaldevi', sans-serif;
    font-weight: 600;
    box-sizing: border-box;
    border-radius: 6px;
    border: 2px solid #e7e7e7;
    padding-left: 6px;
    padding-right: 6px;
    height: 2.4em;
    width: 100%;
    box-shadow: #e7e7e7 0 0px 0px;
    transition-property: box-shadow;
    transition-duration: var(--transition-duration-to);
}
.field:hover {
    box-shadow: #e7e7e7 0 6px 12px;
}

.login-gap {
    height: 1em;
}

.login-button-container {
    padding-top:0.6em;
    display: flex;
    justify-content: center;
}
.login-button {
    font-family: 'Yaldevi', sans-serif;
    font-size: 1em;
    width: 5.6em;
    height: 2.6em;
    border-radius: 12px;
    background-color: #532680;
    background: linear-gradient(135deg, #A64DFF, #532680);
    color: white;
    box-shadow: #532680 0 2px 4px;
    transition-property: var(--transition-property);
    transition-duration: var(--transition-duration-to);
    border: 1px solid #532680;
    cursor: pointer;
}
.login-button:hover {
    box-shadow: #532680 0 6px 12px;
}
.login-button:active {
    box-shadow: #532680 0 0px 0px;
    transform: var(--scale-active);
}
.login-button:disabled {
    width: 6.8em;
    background: linear-gradient(135deg, #A64DFF, #532680);
    box-shadow: #532680 0 0px 0px;
    opacity: 0.7;
    cursor: auto;
}

#collection .flexbox-container {
    max-width: 100%;
    width: initial;
}

#collection-field {
    width: 40em;
    height: 25em;
    max-width: 100%;
}