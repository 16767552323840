* {
  margin: 0;
  text-overflow: ellipsis;
  /* box-sizing: border-box; */
}

html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  font-family: 'Yaldevi', sans-serif;
}
#root {
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
  font-weight: initial !important;
}
