.footer {
    /* position: absolute; */
    /* top: calc(100%-5em); */
    bottom: 0;
    /* transform: translateY(100%); */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5em;
}
.footer .flexbox-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1080px;
    padding: 1em 2em 1em 2em;
    gap: 1em;
    font-weight: 600;
    color: #777;
}
.footer-copyright-container {
    display: flex;
    flex-grow: 1;
}

.footer-date {
    text-align: right;
}